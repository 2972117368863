import React from "react"
import { Row, Col, Container, Image } from "react-bootstrap"
import LazyLoad from 'react-lazyload';
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function TqPage() {
    return (
        <>
            <Layout pageInfo={{ pageName: "thank you page" }}>
                <SEO title="Thank You Page" keywords={[`Emotional Mastery`, `Emotional`, `Mastery`]} />
                <LazyLoad>
                    <div className="jumbotron bg-em-primary-tq">
                        <Container >
                            <Row className="__em-content-align-center-tq">
                                <Col lg={12} md={12} sm={12}>
                                    <div className="__em-logo-emotional">
                                        <Image className="img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/brain-object.png" />
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12}>
                                    <div className="__em-mockup-device">
                                        <Image className="img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/Logo-EM.png" />
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="__wrape-headline-em">
                                        <p className="text-left text-white">
                                            Hello Ladies
                                        </p>
                                        <p className="text-left text-white">
                                            Saya ucapkan selamat karena kamu berhasil masuk dalam daftar antrian online training:
                                        </p>
                                        <p className="text-left text-white">
                                            <b>Emotional Mastery</b>
                                        </p>
                                        <p className="text-left text-white">
                                            <i>Better Emotion Better Love Life</i>
                                        </p>
                                        <p className="text-left text-white">
                                            Sambil kamu tidak sabar ingin dapat <b>Special Offer 67% </b>Emotional Mastery
                                        </p>
                                        <p className="text-left text-white">
                                            Kamu akan mendapatkan video pre launch yang sengaja saya buat <b>khusus</b> hanya untuk mereka yang telah terdaftar dalam <b>waiting list Emotional Mastery.</b>
                                        </p>
                                        <p className="text-left text-white">
                                            Dan video kali ini adalah video secret pertama yang berjudul <b>Cara berdamai dengan emosi terpendam</b>
                                        </p>
                                        <p className="text-left text-white">
                                            dari 3 series yang aksesnya akan dikirimkan secara berkala ke email kamu pada tanggal…
                                        </p>
                                        <p className="text-left text-white">
                                            <b>24 Agustus:</b> [Video Secret 2] <b>Benarkah wanita lebih sulit mengendalikan emosi?</b>
                                        </p>
                                        <p className="text-left text-white">
                                            <b>27 Agustus:</b> [Video Secret 3] <b>5 Step mengendalikan emosi</b>
                                        </p>
                                        <p className="text-left text-white">
                                            So,  <b>saya sarankan kamu langsung cek emailmu (inbox, spam, atau promotion)</b> sekarang ya! ^ ^
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </LazyLoad>
            </Layout >
        </>
    )
}

